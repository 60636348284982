import React from "react";
import { Link } from "gatsby";

import SEO from "react-seo-component";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

export default () => {
  const {
    siteTitle,
    description,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata();

  return (
    <div className="site-wrapper">
      <header>
        <div className="header-content container">
          <Navbar />
        </div>
      </header>
      <main className="container content">
        <SEO
          title="hakkımda"
          titleTemplate={siteTitle}
          description={description}
          image={`${siteUrl}${image}`}
          pathname={siteUrl}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          twitterUsername={twitterUsername}
          author={authorName}
        />
        <div className="vertical-center">
          <div className="hero">
            <img className="hero-image" alt="Comic styled illustration of me" src="/hero-image.svg" />
            <div className="hero-text">
              <h1>selam, ben yüksel</h1>
              <p>
                boş zamanlarının çoğunu kod yazarak geçiren bir avukatım.
                özellikle{" "}
                <Link to="https://developers.google.com/community/experts/directory/profile/profile-y_C3_BCksel_tolun">
                  Google Asistan üzerinde konuşan uygulamalar geliştirmekten
                </Link>{" "}
                ve{" "}
                <Link to="https://www.youtube.com/playlist?list=PLlUlzGHsGYEvUhSTAY2mvezY-C1EsJRxs">
                  {" "}
                  konuşan uygulamalarla ilgili konuşmaktan
                </Link>{" "}
                keyif alıyorum. aynı zamanda{" "}
                <Link to="https://www.adaletyayinevi.com.tr/9786257088718">
                  kişisel verilerin korunması
                </Link>{" "}
                alanında akademik çalışmalar yapıyor ve doktora öğrenimime devam
                ediyorum.
              </p>
              <p>
                benimle iletişime geçmek için{" "}
                <Link to="https://linkedin.com/in/YTolun">linkedin</Link>
                {" veya "}
                <Link to="https://twitter.com/YTolun">twitter'ı</Link>{" "}
                kullanabilir ya da <a href="mailto:tolunyuksel@gmail.com">tolunyuksel@gmail.com</a>{" "}
                adresine mail atabilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};
